.title {
  color: #fff;
}

h1.title {
  font-weight: 700;
  font-size: 1.5rem;
}

h3.title {
  font-weight: 600;
  font-size: 1rem;
}
