@import "styles/variables";

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: $background-color;
  z-index: 100;

  & .contentContainer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow-y: scroll;
    padding: 2rem 0;

    & .content {
      width: 100%;
      max-width: 1080px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      z-index: 300;
    }
  }
}

.noise {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("./assets/images/noise.png");
  background-position: 0px 0px;
  background-size: cover;
  opacity: 0.4;
  mix-blend-mode: screen;
  pointer-events: none;
}
