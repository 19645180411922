.text {
  &.warning {
    color: red;
  }

  &.strong {
    font-weight: 700;
  }

  &.large {
    font-size: 1.375em;
  }

  &.extra-large {
    font-size: 1.5em;
  }

  &.small {
    font-size: 0.875rem;
  }

  &.tiny {
    font-size: 0.6rem;
  }

  &.mono {
    font-variant-numeric: tabular-nums;
  }
}
