$font-family: "Inter", sans-serif;

$background-color: #054120;
$text-color: #fff;

$primary-purple: #8716e8;
$primary-green: #0fb338;
$secondary-green: #13a30e;
$background-green: #0fb33873;
$alternate-pink: #fe6e99;

///
/// SPACING
///
$spacing-xs: 0.5rem;
$spacing-s: 0.75rem;
$spacing-m: 1rem;
$spacing-l: 1.25rem;
$spacing-xl: 1.5rem;
