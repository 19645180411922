@import "../../styles/variables";

.container {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: stretch;
  align-self: stretch;
  flex-shrink: 0;

  .leftArea,
  .centerArea,
  .rightArea {
    flex: 1;
  }

  @media only screen and (max-width: 820px) {
    padding: 2rem 0.3rem;
    .leftArea {
      display: none;
    }
  }

  .centerArea {
    a {
      padding: 0 1rem;
      text-decoration: none;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      color: white;
      display: flex;
      align-items: baseline;

      & > *:last-child {
        margin-left: 0.5rem;
      }
    }
  }
}
