@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Kaisar Black";
  src: url(assets/fonts/Kaisar/Kaisar\ Black.ttf);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}
